import React from "react";
import { Row, Container, Breadcrumb, Card } from "react-bootstrap";
import { navigate } from "gatsby";
import { useSelector } from "react-redux";

import Layout from "../../components/layout";
import Seo from "../../components/seo";
import { SignIn, isUserSignedIn } from "../../components/accountManagement";

const uri = "/account/signin/";

const SignInPage = ({ location }) => {
  const userData = useSelector((state) => state.userData);

  return (
    <Layout pageInfo={{ pageName: "signin" }}>
      <Seo title="Sign In" keywords={[`signing`, `login`]} description="Sign In page" url={uri} />
      <Container fluid>
        <Row className="justify-content-left my-3">
          <Breadcrumb>
            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
            <Breadcrumb.Item href="/account/">Account</Breadcrumb.Item>
            <Breadcrumb.Item active>Sign In</Breadcrumb.Item>
          </Breadcrumb>
        </Row>

        {isUserSignedIn(userData) ? (
          <>User is signed in {navigate("/")}</>
        ) : (
          <Row className="d-flex flex-wrap">
            <Card className="account-mgmt-card">
              <Card.Body>
                <SignIn
                  onSignIn={() => navigate("/")}
                  signUpClick={() => navigate("/account/signup/")}
                  forgotPasswordClick={() => navigate("/account/forgot-password/")}
                  showLogo={false}
                />
              </Card.Body>
            </Card>
          </Row>
        )}
      </Container>
    </Layout>
  );
};

export default SignInPage;
